import Vue from 'vue';
import router, {DISABLE_FOR_LOGGED, PATH_TO_REDIRECT_IF_LOGGED} from './routers';
import store from './store/store';
import {UPDATE_USER_INFO} from './store/mutationTypes';
import appRoutes from './league/leagueRouters';

import './utils/filters';
import './utils/plugins';
import './utils/injections';
import './utils/social';
import './utils/directives';
import './assets/css/main.scss';
import LoaderComponent from './components/LoaderComponent';
import * as ACTION from '@/store/actionTypes';
import {
  GET_CHANNEL_MENU,
  GET_CHANNEL_PAGES,
  GET_CHANNEL_SETTINGS,
  GET_COLOR_SETTINGS, GET_EVENT_CATEGORIES, GET_PARTICIPANT_GROUP_LIST, GET_ALL_PARTICIPANTS,
  GET_PROFILE,
} from './store/actionTypes';
import {DEFAULT_LANGUAGE, LANGUAGES} from './const';
import VisaicV3Analytics from '@/components/base-components/Analytics/VisaicV3Analytics';
import {GET_GEO_DATA} from '@/store/actionTypes';
import ErrorReloadScreen from '@/views/ErrorReloadScreen.vue';
import {mapGetters} from 'vuex';

Vue.i18n.add(DEFAULT_LANGUAGE, LANGUAGES[DEFAULT_LANGUAGE]);
Vue.i18n.set(DEFAULT_LANGUAGE);

new Vue({
  el: '#app',
  store,
  router,
  components: {
    LoaderComponent,
    ErrorReloadScreen,
  },
  template: `
    <error-reload-screen>
      <div
        v-if="!app"
        class='d-flex align-items-center justify-content-center'
        :style="{height: '100vh'}"
      >
        <LoaderComponent/>
      </div>
      <component :is="app" v-else/>
    </error-reload-screen>`,
  data() {
    return {
      app: false,
    };
  },
  computed: {
    ...mapGetters({
      isLogged: 'isLogged',
    }),
  },
  watch: {
    isLogged(newV, old) {
      if (!old && newV && DISABLE_FOR_LOGGED.includes(router.currentRoute.path)) {
        router.replace(PATH_TO_REDIRECT_IF_LOGGED);
      }
    },
  },
  mounted() {
    store.dispatch(ACTION.GET_CHANNEL_KEY).then(() => store.dispatch(
        ACTION.GET_API_KEY,
        {channel_key: Vue.$storage.get('channel_key')},
    )).then(() => Promise.allSettled([
      store.dispatch(GET_CHANNEL_MENU),
      store.dispatch(GET_CHANNEL_PAGES),
      store.dispatch(GET_COLOR_SETTINGS),
      store.dispatch(GET_CHANNEL_SETTINGS),
      store.dispatch(GET_PROFILE).catch(() => store.dispatch(GET_GEO_DATA)),
    ])).then(() => Promise.allSettled([
      store.dispatch(GET_PARTICIPANT_GROUP_LIST),
      store.dispatch(GET_ALL_PARTICIPANTS),
      store.dispatch(GET_EVENT_CATEGORIES),
    ])).catch((err) => {
      store.commit(UPDATE_USER_INFO, null);
      return err;
    }).finally(async () => {
      const newRoutersArr = [
        ...(appRoutes),
        ...(store.getters.getChannelRoute || []),
        {
          path: '*',
          redirect: '/404',
        },
      ];
      newRoutersArr.forEach((route) => router.addRoute(route));

      this.app = await import('./league/LeagueApp').then((m) => m.default);

      VisaicV3Analytics.init({
        userId: store.state.auth?.user?.id,
        ip: store.state?.geo?.ip,
        profile: store.state.channel?.settings?.['yare-analytics']?.['yare-analytics-key'],
        apiKey: Vue.$storage.get('api_key'),
      });
    });
  },
});
